
import { defineComponent } from "vue";
import { IonPage, IonContent } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import MainHeader from "../components/MainHeader.vue";
import MainFooter from "../components/MainFooter.vue";
import { useRoute } from "vue-router";
//import { useStore } from "@/store";

export default defineComponent({
	setup() {
		const { t } = useI18n({ useScope: "global" });
		// const router = useRouter();
		const route = useRoute();
		const orderNo = route.params.order_no;
		const query = route.query;
		//const storeX = useStore();

		return {
			t,
			orderNo,
			query,
		};
	},
	components: {
		IonPage,
		IonContent,
		MainHeader,
		MainFooter,
	},
});
